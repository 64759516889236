import React, { useEffect, useState } from "react";
import { API_URL, isDev } from "../utils/constants";
import { getCustomers } from "../EmergencyCheckout/vend";
import customFetch from "../utils/fetch";

function Input({
  label,
  id,
  placeholder,
  onChange,
  error,
  ...rest
}: {
  label: string;
  id?: string;
  placeholder: string;
  onChange?: (e: any) => void;
  error?: boolean;
  type?: string;
}) {
  return (
    <>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </label>
      <input
        className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
        id={id}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      {error && (
        <p className="text-red-500 text-xs italic">
          Please fill out this field.
        </p>
      )}
    </>
  );
}

function updateOrderStatusToClosed(orderId: string, worldpayReturn: string) {
  return customFetch(`${API_URL}/orders/${orderId}/close`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ worldpayReturn }),
  });
}

export function VendCheckoutForm({ projectId }: { projectId: string }) {
  const [transactionSetupId, setSetupId] = useState("");
  const [cost, setCost] = useState("");
  const [data, setData] = useState();
  const [customers, setCustomers] = useState();
  const [customerId, setCustomerId] = useState();
  const [orderDetails, setOrderDetails] = useState("");
  const [orderId, setOrderId] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [success, setSuccess] = useState(false);
  const [vendCustomer, setVendCustomer] = useState<any>();
  const [sendingTokenizedTransaction, setSendingTransaction] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params: { [key: string]: string } = {};

  new URLSearchParams(window.location.href.split("?")[1]).forEach(
    (entry, key) => {
      params[key] = entry;
    }
  );

//surcharge
  const parser= new URL(window.location.href);
  const fwc_loc= parser.pathname.split("/")[1];

  useEffect(() => {
    if (!customers) {
      getCustomers().then((customers) => {
        setCustomers(customers?.customers);
      });
    }

    const dataListener = (event: any) => {
      let eventData;

      if (typeof event?.data === "string") {
        eventData = JSON.parse(event.data);
      } else {
        eventData = event.data;
      }
      // When in vend
      if (eventData?.step === "DATA") {
        setData(eventData);
        if (eventData?.register_sale?.customer_id) {
          setCustomerId(eventData?.register_sale?.customer_id);

	if(fwc_loc=='worth-fee'){
                eventData.payment.amount=parseFloat((eventData.payment.amount * 1.035).toFixed(2));
        }

          setCost(eventData?.payment?.amount);
        }
      }

      if (
        eventData?.message === "worldpay-transaction-declined" &&
        orderId !== ""
      ) {
        updateOrderStatusToClosed(orderId, eventData?.serializedParams);
      }

      if (
        eventData?.message === "worldpay-transaction-failure" &&
        orderId !== ""
      ) {
        updateOrderStatusToClosed(orderId, eventData?.serializedParams).then(
          () => {
            const message = JSON.stringify({
              step: "DECLINE",
            });
            window.parent.postMessage(message, params.origin);
          }
        );
      }

      // Response from child iframe
      if (
        eventData?.message === "worldpay-transaction-success" &&
        orderId !== ""
      ) {
        updateOrderStatusToClosed(orderId, eventData?.serializedParams).then(
          () => {
            const message = JSON.stringify({
              step: "ACCEPT",
            });
            window.parent.postMessage(message, params.origin);
          }
        );
      }
    };

    window.addEventListener("message", dataListener, false);

    if (customerId && vendCustomer?.vendUserId !== customerId) {
      customFetch(`${API_URL}/tokens/user/${customerId}`).then(
        (vendCustomer) => {
          setVendCustomer(vendCustomer.vendUser);
        }
      );
    }

    if (!data) {
      const message = JSON.stringify({
        step: "DATA",
      });
      window.parent.postMessage(message, params.origin);
    }
  }, [orderId, customers, customerId, vendCustomer, data, params]);

  function handleOrder() {
    // We will call the order API here which will give us some transactionSetupID
    // back that we can use to render a hosted checkout field.
    customFetch(`${API_URL}/orders/singleTimePayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId,
        amount: cost,
        vendCustomer: customerId,
        orderDetails,
      }),
    }).then((res) => {
      setOrderId(res?.order?.id);
      setSetupId(res?.order?.transactionSetupId);
    });
  }

  function handleTokenizedOrder() {
    setSendingTransaction(true);

    customFetch(`${API_URL}/orders/singleTimeTokenizedPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId,
        amount: cost,
        vendCustomer: customerId,
        orderDetails,
      }),
    }).then((res) => {
      if (res.success) {
        setSuccess(true);
        const message = JSON.stringify({
          step: "ACCEPT",
        });
        window.parent.postMessage(message, params.origin);
      } else {
        setErrorMessage("Card failed to be charged");
      }
      setSendingTransaction(false);
    });
  }

  return !customerId && data ? (
    <div className="bg-gray-200 flex items-center flex-col max-w-screen-lg mx-auto p-6">
      <div className="bg-white w-full p-6 flex flex-col">
        <h2>Please select a customer in vend before checking out</h2>
      </div>
    </div>
  ) : data ? (
    <div className="bg-gray-200 flex items-center flex-col max-w-screen-lg mx-auto p-6">
      <div className="bg-white w-full p-6 flex flex-col">
        {errorMessage && (
          <div className="flex items-center justify-center border-red-300 border p-4 mb-4 w-full">
            {errorMessage}
          </div>
        )}
        {success && (
          <div className="flex items-center justify-center border-green-300 border p-4 mb-4 w-full">
            Success! The transaction is complete. Please record the payment in
            Vend.
          </div>
        )}
        <div className="flex flex-col md:flex-col">
          <div className="flex-1 w-full">
            <div className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-6">
              Checkout Details
            </div>
            <div className="flex justify-between pb-4">
              <div className="w-2/3">
                <Input
                  label="Order Note"
                  placeholder="Info about order"
                  onChange={(e) => setOrderDetails(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col py-4 w-full md:py-0 justify-between">
            <div>
              <div className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-6">
                Order Summary
              </div>
              <div className="py-4 pb-6">
                <div className="flex justify-between py-1 text-md items-center">
                  <div className="text-gray-900">Total:</div>
                  <div className="text-xl text-gray-900">
                    ${cost ? Number.parseFloat(cost).toFixed(2) : "0.00"}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {transactionSetupId !== "" && (
                <iframe
                  className="w-full h-64 overflow-hidden"
                  title="hosted-checkout"
                  src={`https://${
                    isDev ? "certtransaction" : "transaction"
                  }.hostedpayments.com/?TransactionSetupID=${transactionSetupId}`}
                />
              )}
            </div>
          </div>
          {transactionSetupId === "" && (
            <div>
              <button
                disabled={sendingTokenizedTransaction}
                onClick={handleOrder}
                className={`cursor-pointer bg-teal-600 hover:bg-teal-500 shadow-xl px-5 py-2 inline-block text-teal-100 hover:text-white rounded ${
                  sendingTokenizedTransaction && "opacity-50 cursor-not-allowed"
                }`}
              >
                Enter New Card
              </button>
              {vendCustomer?.tokenizedCard?.lastFour && (
                <button
                  disabled={sendingTokenizedTransaction}
                  onClick={handleTokenizedOrder}
                  className={`cursor-pointer ml-4 bg-blue-600 hover:bg-blue-500 shadow-xl px-5 py-2 inline-block text-teal-100 hover:text-white rounded ${
                    sendingTokenizedTransaction &&
                    "opacity-50 cursor-not-allowed"
                  }`}
                >
                  Checkout using card ending in{" "}
                  {vendCustomer?.tokenizedCard?.lastFour}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
