import React, { useEffect, useRef, useState } from "react";
import { getCustomers } from "../EmergencyCheckout/vend";
import { API_URL } from "../utils/constants";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import CurrencyInput from "react-currency-input-field";
import Checkmark from "../checkmark";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import customFetch from "../utils/fetch";

declare global {
  interface Window {
    setTransactionSuccess: any;
  }
}

const dropDownOptions = [
  {
    value: "Aesthetic + Concierge Wellness BR",
    text: "Aesthetic + Concierge Wellness BR ",
  },
  {
    value: "Aesthetic + Concierge Wellness NP",
    text: "Aesthetic + Concierge Wellness NP ",
  },
  {
    value: "Aesthetic + Hormone + Concierge Wellness BR",
    text: "Aesthetic + Hormone + Concierge Wellness BR ",
  },
  {
    value: "Aesthetic + Hormone + Concierge Wellness NP",
    text: "Aesthetic + Hormone + Concierge Wellness NP ",
  },
  {
    value: "Aesthetic + Hormone + IV",
    text: "Aesthetic + Hormone + IV ",
  },
  {
    value: "Aesthetic + Hormone + Metabolic Body",
    text: "Aesthetic + Hormone + Metabolic Body ",
  },
  {
    value: "Aesthetic + Hormone + Metabolic Body + Concierge Wellness BR",
    text: "Aesthetic + Hormone + Metabolic Body + Concierge Wellness BR ",
  },
  {
    value: "Aesthetic + Hormone + Metabolic Body + Concierge Wellness NP",
    text: "Aesthetic + Hormone + Metabolic Body + Concierge Wellness NP ",
  },
  {
    value: "Aesthetic + Hormone Membership",
    text: "Aesthetic + Hormone Membership ",
  },
  {
    value: "Aesthetic + Joovv Membership",
    text: "Aesthetic + Joovv Membership ",
  },
  {
    value: "Aesthetic + Metabolic Body",
    text: "Aesthetic + Metabolic Body ",
  },
  {
    value: "Aesthetic + Metabolic Body + Concierge Wellness BR",
    text: "Aesthetic + Metabolic Body + Concierge Wellness BR ",
  },
  {
    value: "Aesthetic + Metabolic Body + Concierge Wellness NP",
    text: "Aesthetic + Metabolic Body + Concierge Wellness NP ",
  },
  { value: "Aesthetic add on", text: "Aesthetic add on " },
  {
    value: "Aesthetic membership",
    text: "Aesthetic membership ",
  },
  {
    value: "Akkermansia Membership- Auto Ship",
    text: "Akkermansia Membership- Auto Ship $66.30/mo",
  },
  {
    value: "B12 Monthly Membership",
    text: "B12 Monthly Membership ",
  },
  {
    value: "Brain Performance Program",
    text: "Brain Performance Program $200/mo",
  },
  {
    value: "Concierge Wellness (BR)",
    text: "Concierge Wellness (BR) ",
  },
  {
    value: "Concierge Wellness (NP)",
    text: "Concierge Wellness (NP) ",
  },
  {
    value: "Concierge Wellness + Hormone + IV",
    text: "Concierge Wellness + Hormone + IV ",
  },
  {
    value: "Hormone & Metabolic Body Membership",
    text: "Hormone & Metabolic Body Membership ",
  },
  {
    value: "Hormone + Concierge Wellness + IV + Joovv (BR)",
    text: "Hormone + Concierge Wellness + IV + Joovv (BR) ",
  },
  {
    value: "Hormone + Concierge Wellness + IV + Joovv (NP)",
    text: "Hormone + Concierge Wellness + IV + Joovv (NP) ",
  },
  {
    value: "Hormone + Concierge Wellness BR",
    text: "Hormone + Concierge Wellness BR ",
  },
  {
    value: "Hormone + Concierge Wellness NP",
    text: "Hormone + Concierge Wellness NP ",
  },
  { value: "Hormone + IV", text: "Hormone + IV " },
  {
    value: "Hormone + Metabolic Body + Concierge Wellness + IV BR",
    text: "Hormone + Metabolic Body + Concierge Wellness + IV BR ",
  },
  {
    value: "Hormone + Metabolic Body + Concierge Wellness + IV NP",
    text: "Hormone + Metabolic Body + Concierge Wellness + IV NP ",
  },
  {
    value: "Hormone + Metabolic Body + Concierge Wellness BR",
    text: "Hormone + Metabolic Body + Concierge Wellness BR ",
  },
  {
    value: "Hormone + Metabolic Body + Concierge Wellness NP",
    text: "Hormone + Metabolic Body + Concierge Wellness NP ",
  },
  {
    value: "Hormone + Metabolic Body + IV",
    text: "Hormone + Metabolic Body + IV ",
  },
  {
    value: "Hormone + Metabolic Maintenance Plan",
    text: "Hormone + Metabolic Maintenance Plan ",
  },
  {
    value: "Hormone + Metabolic Maintenance Plan + IV",
    text: "Hormone + Metabolic Maintenance Plan + IV ",
  },
  { value: "Hormone Membership", text: "Hormone Membership " },
  {
    value: "HRT Membership- First Responder",
    text: "HRT Membership- First Responder ",
  },
  { value: "IV Membership", text: "IV Membership " },
  { value: "Joovv Membership", text: "Joovv Membership " },
  {
    value: "Julie Brown Membership",
    text: "Julie Brown Membership ",
  },
  {
    value: "Metabolic Body + Concierge Wellness BR",
    text: "Metabolic Body + Concierge Wellness BR ",
  },
  {
    value: "Metabolic Body + Concierge Wellness NP",
    text: "Metabolic Body + Concierge Wellness NP ",
  },
  { value: "Metabolic Body + IV", text: "Metabolic Body + IV " },
  {
    value: "Metabolic Body + PCP",
    text: "Metabolic Body + PCP ",
  },
  {
    value: "Metabolic Body +Concierge Wellness + Joovv BR",
    text: "Metabolic Body +Concierge Wellness + Joovv BR ",
  },
  {
    value: "Metabolic Body +Concierge Wellness + Joovv NP",
    text: "Metabolic Body +Concierge Wellness + Joovv NP ",
  },
  {
    value: "Metabolic Body Membership",
    text: "Metabolic Body Membership ",
  },
  {
    value: "Metabolic Maintenance Plan",
    text: "Metabolic Maintenance Plan ",
  },
  {
    value: "MedPax",
    text: "MedPax",
  },
  {
    value: "MIC Monthly Membership",
    text: "MIC Monthly Membership ",
  },
  {
    value: "MIC+ Monthly Membership",
    text: "MIC+ Monthly Membership ",
  },
  {
    value: "MIC+/B12 Monthly Membership",
    text: "MIC+/B12 Monthly Membership ",
  },
  {
    value: "MIC/B12 Monthly Membership",
    text: "MIC/B12 Monthly Membership ",
  },
  { value: "PCP Membership Plan", text: "PCP Membership Plan " },
  {
    value: "Recurring - Cancel Membership",
    text: "Recurring - Cancel Membership ",
  },
  {
    value: "Recurring - Modify Membership",
    text: "Recurring - Modify Membership ",
  },
  {
    value: "Telemedicine MBP Membership",
    text: "Telemedicine MBP Membership ",
  },
  {
    value: "Trainer Hormone Replacement membership",
    text: "Trainer Hormone Replacement membership ",
  },
  {
    value: "Miscellaneous",
    text: "Miscellaneous",
  },
];

export function VendSubscriptionCreator({
  projectId,
  name,
}: {
  projectId: string;
  name: string;
}) {
  const requestInFlight = useRef(false);
  const [recurrence, setRecurrence] = useState("MONTHLY");
  const [amount, setAmount] = useState<number | null>(0.0);
  const [data, setData] = useState();
  const [customerId, setCustomerId] = useState();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [service, setService] = useState<any>();
  const [isCreatingSubscription, setCreatingSubscription] = useState(false);
  const [vendCustomer, setVendCustomer] = useState<any>();
  const [customers, setCustomers] = useState();
  const [date, setDate] = useState<Moment | null>(moment());
  const [focused, setFocused] = useState<boolean | null>(false);
  const [successMessage, setSuccessMessage] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params: { [key: string]: string } = {};

  new URLSearchParams(window.location.href.split("?")[1]).forEach(
    (entry, key) => {
      params[key] = entry;
    }
  );

  useEffect(() => {
    if (!customers) {
      getCustomers().then((customers) => {
        setCustomers(customers?.customers);
      });
    }

    const dataListener = (event: any) => {
      let eventData;

      if (typeof event?.data === "string") {
        eventData = JSON.parse(event.data);
      } else {
        eventData = event.data;
      }
      // When in vend
      if (eventData?.step === "DATA") {
        setData(eventData);
        if (eventData?.register_sale?.customer_id) {
          setCustomerId(eventData?.register_sale?.customer_id);
        }
      }
    };

    window.addEventListener("message", dataListener, false);

    if (customerId && vendCustomer?.vendUserId !== customerId) {
      customFetch(`${API_URL}/tokens/user/${customerId}`).then(
        (vendCustomer) => {
          setVendCustomer(vendCustomer.vendUser);
        }
      );
    }
    if (!data) {
      const message = JSON.stringify({
        step: "DATA",
      });
      window.parent.postMessage(message, params.origin);
    }
  }, [customers, customerId, data, params, vendCustomer]);

  function handleCreateSubmission() {
    if (!service) {
      setErrorMessage("Please select a service for the client");
      setCreatingSubscription(false);
      return;
    }
    if ((amount && amount <= 0) || !amount) {
      setErrorMessage("Subscription must be more than $0");
      setCreatingSubscription(false);
      return;
    }
    if (!vendCustomer?.tokenizedCard?.lastFour) {
      setErrorMessage("No tokenized card for vend user");
      setCreatingSubscription(false);
      return;
    }
    if (
      !requestInFlight.current &&
      customerId &&
      vendCustomer &&
      date &&
      vendCustomer?.tokenizedCard?.lastFour
    ) {
      requestInFlight.current = true;
      setCreatingSubscription(true);
      const useUpfrontPayment = moment(new Date()).isSame(date, "day");
      customFetch(`${API_URL}/subscriptions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vendUserId: vendCustomer?.id,
          amount,
          service: service.value ? service.value : service,
          recurrence,
          nextPaymentDate: date.startOf("day").toISOString(),
          projectId,
          upfrontPayment: useUpfrontPayment,
        }),
      })
        .then((resp) => {
          if (resp?.subscription?.id) {
            setTimeout(() => {
              setErrorMessage(null);
              setCreatingSubscription(false);
              setSuccessMessage(true);
              setVendCustomer(null);
              const message = JSON.stringify({
                step: "ACCEPT",
                approved_amount: useUpfrontPayment ? amount : 0.0,
              });
              window.parent.postMessage(message, params.origin);
            }, 1000);
          } else {
            setCreatingSubscription(false);
            setErrorMessage(
              "Failed to create subscription, there was an issue with the card on file."
            );
          }
          requestInFlight.current = false;
        })
        .catch(() => {
          setCreatingSubscription(false);
          setErrorMessage(
            "There was an issue with the serer. Please try again later"
          );
        });
    }
  }

  const useUpfrontPayment = moment(new Date()).isSame(date, "day");
  return (
    <div className="flex flex-col items-center max-w-screen-lg p-6 mx-auto bg-gray-200">
      <div className="flex flex-col w-full p-6 bg-white">
        <div className="text-4xl font-semibold">{name}</div>
        {successMessage && (
          <div className="flex items-center justify-center w-full p-4 mb-4 border border-green-300">
            Subscription Created!{" "}
            {useUpfrontPayment &&
              `They were charged $
            ${amount ? amount.toFixed(2) : 0.0} today.`}{" "}
            Their next subscription charge will be on{" "}
            {date?.startOf("day").format("dddd MMMM DD YYYY")}.
          </div>
        )}
        {errorMessage && (
          <div className="flex items-center justify-center w-full p-4 mb-4 border border-red-300">
            {errorMessage}
          </div>
        )}
        <div className="flex flex-col-reverse md:flex-row">
          <div className="flex-1 w-full md:w-3/5 ">
            <div className="block mb-6 font-bold tracking-wide text-gray-700 uppercase text-md">
              Subscription Details
            </div>
            <div className="flex justify-between">
              <div className="w-1/2 pr-4">
                <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Subscription Amount
                </label>
                <CurrencyInput
                  id="input-example"
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded shadow-inner appearance-none focus:outline-none focus:bg-white"
                  name="input-name"
                  placeholder="$0.00"
                  defaultValue={0}
                  allowDecimals={true}
                  prefix="$"
                  decimalsLimit={2}
                  onChange={(value) => setAmount(value)}
                />
              </div>
              <div className="w-1/2">
                <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Select Subscription Service
                </label>
                <select
                  value={service}
                  onChange={(service) => setService(service.target.value)}
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded shadow-inner appearance-none focus:outline-none focus:bg-white"
                >
                  <option value="" disabled selected>
                    Please select membership
                  </option>
                  {dropDownOptions.map((option) => (
                    <option value={option.value}>{option.text}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex pt-4">
              <div className="w-1/2 pr-4">
                <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Future Payment Date
                </label>
                <SingleDatePicker
                  id="date-picker"
                  date={date}
                  openDirection="up"
                  withFullScreenPortal
                  focused={focused}
                  onDateChange={(date) => setDate(date)}
                  onFocusChange={(f) => setFocused(f.focused)}
                />
              </div>
              <div className="w-1/3 pr-4">
                <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Frequency
                </label>
                <select
                  value={recurrence}
                  onChange={(rec) => setRecurrence(rec.target.value)}
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded shadow-inner appearance-none focus:outline-none focus:bg-white"
                >
                  <option value="MONTHlY">Monthly</option>
                  <option value="YEARLY">Yearly</option>
                </select>
              </div>
            </div>
            <button
              onClick={handleCreateSubmission}
              disabled={isCreatingSubscription}
              className="inline-block px-5 py-2 mt-4 text-teal-100 bg-teal-600 rounded shadow-xl cursor-pointer hover:bg-teal-500 hover:text-white"
            >
              {isCreatingSubscription
                ? "Creating Subscription..."
                : useUpfrontPayment
                ? `Create subscription and charge $${
                    amount ? amount.toFixed(2) : 0.0
                  } today`
                : "Create subscription"}
            </button>
          </div>
          <div className="flex flex-col w-full py-4 md:w-2/5 md:py-0 md:pb-3 md:pl-8">
            <div>
              <div className="block mb-6 font-bold tracking-wide text-gray-700 uppercase text-md">
                Customer Card
              </div>
            </div>
            <div className="pt-6">
              {vendCustomer && customerId && (
                <div className="flex items-center w-full h-full p-2 px-4 bg-gray-200 border border-green-400 rounded cursor-pointer">
                  <div className="flex pr-4 align-middle">
                    <div className="flex items-center justify-center w-6 h-6 bg-green-300 border border-green-600 rounded-full">
                      <Checkmark className="w-3 h-3 text-white" />
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="text-sm uppercase">
                      {vendCustomer?.vendUser?.first_name}{" "}
                      {vendCustomer?.vendUser?.last_name}
                    </div>
                    <div style={{ verticalAlign: "sub" }}>
                      <span
                        style={{ lineHeight: "30px", verticalAlign: "middle" }}
                      >
                        **** **** ****
                      </span>{" "}
                      {vendCustomer?.tokenizedCard?.lastFour}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
